import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ScrollIndicator = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const theme = useTheme();

  const handleScroll = () => {
    const winScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        width: `${scrollTop}%`,
        height: "2.5px",
        backgroundColor: theme.palette.primary.main,
        top: 0,
        left: 0,
        zIndex: 99,
        transition: "width 1s cubic-bezier(.2,.72,.7,1.18)",
      }}
    />
  );
};

export default ScrollIndicator;
