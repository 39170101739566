import React from "react";
import { Box, PaletteMode } from "@mui/material";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import FloatingActionButton from "../components/FloatingActionButton";

interface LayoutProps {
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
}

const Layout: React.FC<LayoutProps> = ({ setMode }) => {
  const theme = useTheme();
  return (
    <Box bgcolor={theme.palette.surface?.main}>
      <Navbar setMode={setMode} />
      <Outlet />
      <FloatingActionButton />
      <Footer />
    </Box>
  );
};

export default Layout;
