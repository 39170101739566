import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Avatar,
  Divider,
} from "@mui/material";
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import PiInvLogo from "./PiInvLogo";
import { md3ThemeJson } from "../../Theme";

const Footer = () => {
  return (
    <>
      <Divider />
      <Box
        sx={{
          color: md3ThemeJson.schemes.dark.onSurface,
          backgroundColor: md3ThemeJson.schemes.dark.surface,
          paddingBottom: "3rem",
          paddingTop: "3rem",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="body2"
            sx={{ marginBottom: "3rem", textAlign: "left" }}
          >
            <PiInvLogo />
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
            container
            spacing={4}
          >
            <Grid
              sx={{
                paddingTop: "0 !important",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                flexWrap: "wrap",
                width: "30%",
              }}
              item
              xs={12}
              md={3}
            >
              <Typography variant="body2" sx={{ mb: 2, textAlign: "left" }}>
                Pioneer Investing offers PiTrade, a copy-trading application
                designed to provide users with a seamless and powerful trading
                experience. The application caters to both novice and
                experienced traders.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Avatar sx={{ bgcolor: "inherit" }}>
                  <IconButton
                    component="a"
                    href="https://www.facebook.com/pioneerinvesting"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#1877f2" }}
                  >
                    <Facebook />
                  </IconButton>
                </Avatar>
                <Avatar sx={{ bgcolor: "inherit" }}>
                  <IconButton
                    component="a"
                    href="https://www.instagram.com/pioneerinvestinginc"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#e02d69" }}
                  >
                    <Instagram />
                  </IconButton>
                </Avatar>
                <Avatar sx={{ bgcolor: "inherit" }}>
                  <IconButton
                    component="a"
                    href="https://www.linkedin.com/company/pioneer-investing"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "#007ebb" }}
                  >
                    <LinkedIn />
                  </IconButton>
                </Avatar>
                <Avatar sx={{ bgcolor: "inherit" }}>
                  <IconButton
                    component="a"
                    href="https://x.com/PioneerInvestin"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "white" }}
                  >
                    <XIcon />
                  </IconButton>
                </Avatar>
              </Box>
            </Grid>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{ textAlign: "left", margin: "0 3rem" }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  COMPANY
                </Typography>
                <Typography variant="body2">
                  <Link
                    href="/aboutus"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    About us
                  </Link>
                  <Link
                    href="/pricing"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    Pricing
                  </Link>
                  <Link
                    href="career"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    Career
                  </Link>
                  <Link
                    href="/faq"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    FAQ
                  </Link>
                  <Link
                    href="/support"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    Support
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  LEGAL
                </Typography>
                <Typography variant="body2">
                  <Link
                    href="/termsandconditions"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    Terms and Conditions
                  </Link>
                  <Link
                    href="/privacypolicy"
                    color="inherit"
                    underline="hover"
                    sx={{ display: "block", mb: 1 }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Typography variant="body2" sx={{ mt: 2, textAlign: "left" }}>
            {`© ${new Date().getFullYear()}. All rights reserved.`}
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
