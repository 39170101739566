import { Box } from "@mui/material";
import React from "react";
import Cards from "./Cards";
// import Strategizers from "./Strategizers";
import Contact from "./Contact";
import HeroBanner from "./HeroBanner";
import FloatingActionButton from "../components/FloatingActionButton";

const Home = () => {
  return (
    <Box>
      <HeroBanner />
      <Cards />
      {/* <Strategizers /> */}
      <Contact />
      {/* <FloatingActionButton /> */}
    </Box>
  );
};

export default Home;
