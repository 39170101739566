import React from "react";
import {
  Button,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import { NavLink } from "react-router-dom";

const Strategizers = () => {
  return (
    <Container>
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          p: "3rem",
          backgroundColor: "inherit",
          color: "inherit",
          borderRadius: "16px",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            item
            xs={6}
            md={6}
          >
            {/* First Row */}
            <Grid item xs={6} md={6}>
              <Avatar
                alt="Remy Sharp"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRabpp9cRSP2RA-PsVT6watU3wUMgam3AI_ow&s"
                sx={{ borderRadius: "100%", width: "8rem", height: "8rem" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography
                color="primary"
                variant="h4"
                component="div"
                gutterBottom
              >
                Strategizers
              </Typography>
              <Typography variant="subtitle1" component="div" gutterBottom>
                Discover the strategies and portfolio of seasoned traders. and
                invest in strategizers that match your requirements and risk
                tolerance.
              </Typography>
              <NavLink to="/strategizers">
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                  Unleash the power of strategizers
                </Button>
              </NavLink>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Strategizers;
