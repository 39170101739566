import * as React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import DownloadIcon from "@mui/icons-material/Download";
import AppStoreLogo from "./AppStoreLogo";
import PlayStoreLogo from "./PlayStoreLogo";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function FloatingActionButton() {
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <SpeedDial
        ariaLabel="Download"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<DownloadIcon />}
      >
        <SpeedDialAction
          key="Play Store"
          icon={<PlayStoreLogo />}
          tooltipTitle="Play Store"
          onClick={handleClick}
        />
        <SpeedDialAction
          key="App Store"
          icon={<AppStoreLogo />}
          tooltipTitle="App Store"
          onClick={handleClick}
        />
      </SpeedDial>

      {/* Dialog for showing the "Coming Soon" message */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="coming-soon-title"
        PaperProps={{
          sx: { padding: 3, borderRadius: 3, textAlign: "center" },
        }}
      >
        <DialogTitle id="coming-soon-title">
          <Typography variant="h6">Coming Soon!</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            We are working to bring this feature to you soon. Stay tuned!
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose} variant="contained" color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
