import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import MobileMain from "./mobile/main";
import DesktopMain from "./desktop/main";
import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import getAppTheme from "./Theme";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = React.useState<PaletteMode>(
    prefersDarkMode ? "dark" : "light",
  );

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setMode(
        savedTheme === "system"
          ? prefersDarkMode
            ? "dark"
            : "light"
          : (savedTheme as PaletteMode),
      );
    } else {
      setMode(prefersDarkMode ? "dark" : "light");
    }
  }, [prefersDarkMode]);

  const appTheme = createTheme(getAppTheme(mode));

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <div className="App">
        {isMobile ? (
          <MobileMain setMode={setMode} />
        ) : (
          <DesktopMain setMode={setMode} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
