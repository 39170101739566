import React, { useState, MouseEvent, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Box,
  ButtonBase,
  ListItemButton,
  Popover,
  Divider,
  Drawer,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  PaletteMode,
  useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import "../styles/navbar.css";
import ScrollIndicator from "./ScrollIndicator";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import PiInvLogo from "./PiInvLogo";
import { md3ThemeJson } from "../../Theme";

interface NavbarProps {
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
}

const Navbar: React.FC<NavbarProps> = ({ setMode }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeTab, setActiveTab] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<string>("system");
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setSelectedTheme(savedTheme);
      setMode(
        savedTheme === "system"
          ? prefersDarkMode
            ? "dark"
            : "light"
          : (savedTheme as PaletteMode),
      );
    } else {
      setMode(prefersDarkMode ? "dark" : "light");
    }
  }, [prefersDarkMode, setMode]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTheme = event.target.value;
    setSelectedTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    if (newTheme === "light") {
      setMode("light");
    } else if (newTheme === "dark") {
      setMode("dark");
    } else {
      setMode(prefersDarkMode ? "dark" : "light");
    }
  };

  return (
    <Box className="navbar">
      <AppBar
        position="fixed"
        sx={{
          background: `${md3ThemeJson.schemes.dark.background}cc`,
          backdropFilter: "blur(6px)",
          color: "inherit",
          overflow: "hidden",
          zIndex: 1100,
          boxShadow: "none",
          height: "4rem",
        }}
      >
        <ScrollIndicator />
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 0,
            }}
          >
            <Link href="/">
              <PiInvLogo />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Link
              underline="none"
              href="/"
              className="navbutton"
              sx={{
                color:
                  activeTab === "home"
                    ? md3ThemeJson.schemes.dark.primary
                    : "inherit",
                textDecoration: "none",
                marginRight: 4,
              }}
              onClick={() => handleTabClick("home")}
            >
              <ButtonBase>
                <ListItemButton
                  className={activeTab === "home" ? "select_dot" : "add_dot"}
                  sx={{
                    height: "100%",
                    padding: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                      "&:hover": {
                        opacity: "0.64",
                        backgroundColor: "transparent",
                      },
                    },
                  }}
                >
                  <Typography>Home</Typography>
                </ListItemButton>
              </ButtonBase>
            </Link>

            <Link
              underline="none"
              className="navbutton"
              href="https://pitrade.pioneerinvesting.com/"
              sx={{
                textDecoration: "none",
                color: md3ThemeJson.schemes.dark.golden,
                marginRight: 4,
              }}
              onMouseEnter={handleMenuOpen}
              onMouseLeave={handleMenuClose}
            >
              <ButtonBase
                sx={{
                  "&:focus": { outline: "none" },
                  WebkitTapHighlightColor: "transparent",
                  "&:hover": {
                    opacity: "0.64",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemButton
                  className={"add_dot"}
                  sx={{
                    height: "100%",
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Typography>PiTrade</Typography>
                </ListItemButton>
              </ButtonBase>
            </Link>

            <Link
              underline="none"
              href="/blogs"
              className="navbutton"
              sx={{
                color:
                  activeTab === "blogs"
                    ? md3ThemeJson.schemes.dark.primary
                    : "inherit",
                textDecoration: "none",
                marginRight: 4,
              }}
              onClick={() => handleTabClick("blogs")}
            >
              <ButtonBase
                sx={{
                  "&:focus": { outline: "none" },
                  WebkitTapHighlightColor: "transparent",
                  "&:hover": {
                    opacity: "0.64",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemButton
                  className={activeTab === "blogs" ? "select_dot" : "add_dot"}
                  sx={{
                    height: "100%",
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <Typography>Blogs</Typography>
                </ListItemButton>
              </ButtonBase>
            </Link>

            <Link
              underline="none"
              className="navbutton"
              sx={{
                color:
                  activeTab === "settings"
                    ? md3ThemeJson.schemes.dark.primary
                    : "inherit",
                textDecoration: "none",
                marginRight: 4,
              }}
              onClick={() => handleTabClick("settings")}
            >
              <ButtonBase
                sx={{
                  "&:focus": { outline: "none" },
                  WebkitTapHighlightColor: "transparent",
                  "&:hover": {
                    opacity: "0.64",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    height: "100%",
                    padding: 0,
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <SettingsIcon
                    onClick={() => setIsDrawerOpen(true)}
                    color="disabled"
                  />
                  <Drawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 1,
                        height: "4rem",
                        justifyContent: "space-between",
                        backgroundColor: theme.palette.surfaceVariant?.main,
                      }}
                      p={2}
                      width="300px"
                      role="presentation"
                    >
                      <Typography
                        sx={{
                          color: theme.palette.surfaceVariant?.contrastText,
                        }}
                        variant="body1"
                      >
                        Settings
                      </Typography>
                      <IconButton onClick={() => setIsDrawerOpen(false)}>
                        <CloseIcon
                          sx={{
                            color: theme.palette.surfaceVariant?.contrastText,
                          }}
                        />
                      </IconButton>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        flex: 1,
                        padding: 2,
                        backgroundColor: theme.palette.surfaceVariant?.main,
                        color: theme.palette.surfaceVariant?.contrastText,
                      }}
                    >
                      <Typography variant="subtitle1">Theme</Typography>
                      <RadioGroup
                        aria-label="theme"
                        name="theme"
                        value={selectedTheme}
                        onChange={handleThemeChange}
                      >
                        <FormControlLabel
                          value="system"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <SettingsBrightnessIcon sx={{ marginRight: 1 }} />
                              System Default
                            </Box>
                          }
                        />
                        <FormControlLabel
                          value="light"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <LightModeIcon sx={{ marginRight: 1 }} />
                              Light
                            </Box>
                          }
                        />
                        <FormControlLabel
                          value="dark"
                          control={<Radio />}
                          label={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <DarkModeIcon sx={{ marginRight: 1 }} />
                              Dark
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </Box>
                  </Drawer>
                </ListItemButton>
              </ButtonBase>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
