import {
  PaletteColor,
  PaletteColorOptions,
  ThemeOptions,
} from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import themeJson from "./CustomTheme.json";

interface Scheme {
  primary: string;
  surfaceTint: string;
  onPrimary: string;
  primaryContainer: string;
  onPrimaryContainer: string;
  secondary: string;
  onSecondary: string;
  secondaryContainer: string;
  onSecondaryContainer: string;
  tertiary: string;
  onTertiary: string;
  tertiaryContainer: string;
  onTertiaryContainer: string;
  error: string;
  onError: string;
  errorContainer: string;
  onErrorContainer: string;
  background: string;
  onBackground: string;
  surface: string;
  onSurface: string;
  surfaceVariant: string;
  onSurfaceVariant: string;
  outline: string;
  outlineVariant: string;
  shadow: string;
  scrim: string;
  inverseSurface: string;
  inverseOnSurface: string;
  inversePrimary: string;
  primaryFixed: string;
  onPrimaryFixed: string;
  primaryFixedDim: string;
  onPrimaryFixedVariant: string;
  secondaryFixed: string;
  onSecondaryFixed: string;
  secondaryFixedDim: string;
  onSecondaryFixedVariant: string;
  tertiaryFixed: string;
  onTertiaryFixed: string;
  tertiaryFixedDim: string;
  onTertiaryFixedVariant: string;
  surfaceDim: string;
  surfaceBright: string;
  surfaceContainerLowest: string;
  surfaceContainerLow: string;
  surfaceContainer: string;
  surfaceContainerHigh: string;
  surfaceContainerHighest: string;
  golden: string;
  onGolden: string;
  goldenContainer: string;
  onGoldenContainer: string;
}

interface ExtendedColor {
  name: string;
  color: string;
  description: string;
  harmonized: boolean;
}

interface SchemeType {
  light: Scheme;
  "light-medium-contrast": Scheme;
  "light-high-contrast": Scheme;
  dark: Scheme;
  "dark-medium-contrast": Scheme;
  "dark-high-contrast": Scheme;
}

interface ThemeJson {
  coreColors: {
    primary: string;
  };
  extendedColors: ExtendedColor[];
  schemes: SchemeType;
}

declare module "@mui/material/styles" {
  interface Palette {
    surface?: PaletteColor;
    surfaceVariant?: PaletteColor;
    surfaceBright?: PaletteColor;
    inverseSurface?: PaletteColor;
    tertiary?: PaletteColor;
    primaryContainer?: PaletteColor;
    secondaryContainer?: PaletteColor;
    tertiaryContainer?: PaletteColor;
    surfaceContainerLow?: PaletteColor;
    golden?: PaletteColor;
    onGolden?: PaletteColor;
    goldenContainer?: PaletteColor;
    onGoldenContainer?: PaletteColor;
  }
  interface PaletteOptions {
    surface?: PaletteColorOptions;
    surfaceVariant?: PaletteColorOptions;
    surfaceBright?: PaletteColorOptions;
    inverseSurface?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
    primaryContainer?: PaletteColorOptions;
    secondaryContainer?: PaletteColorOptions;
    tertiaryContainer?: PaletteColorOptions;
    surfaceContainerLow?: PaletteColorOptions;
    golden?: PaletteColorOptions;
    onGolden?: PaletteColorOptions;
    goldenContainer?: PaletteColorOptions;
    onGoldenContainer?: PaletteColorOptions;
  }
}

export const md3ThemeJson = themeJson as ThemeJson;

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    primary: {
      light: md3ThemeJson.schemes["light-medium-contrast"].primary,
      main: md3ThemeJson.schemes.light.primary,
      dark: md3ThemeJson.schemes["light-high-contrast"].primary,
      contrastText: md3ThemeJson.schemes.light.onPrimary,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].primary,
        main: md3ThemeJson.schemes.dark.primary,
        dark: md3ThemeJson.schemes["dark-high-contrast"].primary,
        contrastText: md3ThemeJson.schemes.dark.onPrimary,
      }),
    },
    secondary: {
      light: md3ThemeJson.schemes["light-medium-contrast"].secondary,
      main: md3ThemeJson.schemes.light.secondary,
      dark: md3ThemeJson.schemes["light-high-contrast"].secondary,
      contrastText: md3ThemeJson.schemes.light.onSecondary,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].secondary,
        main: md3ThemeJson.schemes.dark.secondary,
        dark: md3ThemeJson.schemes["dark-high-contrast"].secondary,
        contrastText: md3ThemeJson.schemes.dark.onSecondary,
      }),
    },
    tertiary: {
      light: md3ThemeJson.schemes["light-medium-contrast"].tertiary,
      main: md3ThemeJson.schemes.light.tertiary,
      dark: md3ThemeJson.schemes["light-high-contrast"].tertiary,
      contrastText: md3ThemeJson.schemes.light.onTertiary,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].tertiary,
        main: md3ThemeJson.schemes.dark.tertiary,
        dark: md3ThemeJson.schemes["dark-high-contrast"].tertiary,
        contrastText: md3ThemeJson.schemes.dark.onTertiary,
      }),
    },
    error: {
      light: md3ThemeJson.schemes["light-medium-contrast"].error,
      main: md3ThemeJson.schemes.light.error,
      dark: md3ThemeJson.schemes["light-high-contrast"].error,
      contrastText: md3ThemeJson.schemes.light.onError,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].primary,
        main: md3ThemeJson.schemes.dark.error,
        dark: md3ThemeJson.schemes["dark-high-contrast"].error,
        contrastText: md3ThemeJson.schemes.dark.onError,
      }),
    },
    surface: {
      light: md3ThemeJson.schemes["light-medium-contrast"].surface,
      main: md3ThemeJson.schemes.light.surface,
      dark: md3ThemeJson.schemes["light-high-contrast"].surface,
      contrastText: md3ThemeJson.schemes.light.onSurface,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].surface,
        main: md3ThemeJson.schemes.dark.surface,
        dark: md3ThemeJson.schemes["dark-high-contrast"].surface,
        contrastText: md3ThemeJson.schemes.dark.onSurface,
      }),
    },
    surfaceVariant: {
      light: md3ThemeJson.schemes["light-medium-contrast"].surfaceVariant,
      main: md3ThemeJson.schemes.light.surfaceVariant,
      dark: md3ThemeJson.schemes["light-high-contrast"].surfaceVariant,
      contrastText: md3ThemeJson.schemes.light.onSurfaceVariant,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].surfaceVariant,
        main: md3ThemeJson.schemes.dark.surfaceVariant,
        dark: md3ThemeJson.schemes["dark-high-contrast"].surfaceVariant,
        contrastText: md3ThemeJson.schemes.dark.onSurfaceVariant,
      }),
    },
    surfaceBright: {
      light: md3ThemeJson.schemes["light-medium-contrast"].surfaceBright,
      main: md3ThemeJson.schemes.light.surfaceBright,
      dark: md3ThemeJson.schemes["light-high-contrast"].surfaceBright,
      contrastText: md3ThemeJson.schemes.light.onSurface,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].surfaceBright,
        main: md3ThemeJson.schemes.dark.surfaceBright,
        dark: md3ThemeJson.schemes["dark-high-contrast"].surfaceBright,
        contrastText: md3ThemeJson.schemes.dark.onSurface,
      }),
    },
    surfaceContainerLow: {
      light: md3ThemeJson.schemes["light-medium-contrast"].surfaceContainerLow,
      main: md3ThemeJson.schemes.light.surfaceContainerLow,
      dark: md3ThemeJson.schemes["light-high-contrast"].surfaceContainerLow,
      contrastText: md3ThemeJson.schemes.light.onSurface,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].surfaceContainerLow,
        main: md3ThemeJson.schemes.dark.surfaceContainerLow,
        dark: md3ThemeJson.schemes["dark-high-contrast"].surfaceContainerLow,
        contrastText: md3ThemeJson.schemes.dark.onSurface,
      }),
    },
    inverseSurface: {
      light: md3ThemeJson.schemes["light-medium-contrast"].inverseSurface,
      main: md3ThemeJson.schemes.light.inverseSurface,
      dark: md3ThemeJson.schemes["light-high-contrast"].inverseSurface,
      contrastText: md3ThemeJson.schemes.light.inverseOnSurface,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].inverseSurface,
        main: md3ThemeJson.schemes.dark.inverseSurface,
        dark: md3ThemeJson.schemes["dark-high-contrast"].inverseSurface,
        contrastText: md3ThemeJson.schemes.dark.inverseOnSurface,
      }),
    },
    primaryContainer: {
      light: md3ThemeJson.schemes["light-medium-contrast"].primaryContainer,
      main: md3ThemeJson.schemes.light.primaryContainer,
      dark: md3ThemeJson.schemes["light-high-contrast"].primaryContainer,
      contrastText: md3ThemeJson.schemes.light.onPrimaryContainer,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].primaryContainer,
        main: md3ThemeJson.schemes.dark.primaryContainer,
        dark: md3ThemeJson.schemes["dark-high-contrast"].primaryContainer,
        contrastText: md3ThemeJson.schemes.dark.onPrimaryContainer,
      }),
    },
    secondaryContainer: {
      light: md3ThemeJson.schemes["light-medium-contrast"].secondaryContainer,
      main: md3ThemeJson.schemes.light.secondaryContainer,
      dark: md3ThemeJson.schemes["light-high-contrast"].secondaryContainer,
      contrastText: md3ThemeJson.schemes.light.onSecondaryContainer,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes["dark-medium-contrast"].secondaryContainer,
        main: md3ThemeJson.schemes.dark.secondaryContainer,
        dark: md3ThemeJson.schemes["dark-high-contrast"].secondaryContainer,
        contrastText: md3ThemeJson.schemes.dark.onSecondaryContainer,
      }),
    },
    goldenContainer: {
      light: md3ThemeJson.schemes.light.goldenContainer,
      main: md3ThemeJson.schemes.light.goldenContainer,
      dark: md3ThemeJson.schemes.light.goldenContainer,
      contrastText: md3ThemeJson.schemes.light.onGoldenContainer,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes.dark.goldenContainer,
        main: md3ThemeJson.schemes.dark.goldenContainer,
        dark: md3ThemeJson.schemes.dark.goldenContainer,
        contrastText: md3ThemeJson.schemes.dark.onGoldenContainer,
      }),
    },
    onGoldenContainer: {
      light: md3ThemeJson.schemes.light.onGoldenContainer,
      main: md3ThemeJson.schemes.light.onGoldenContainer,
      dark: md3ThemeJson.schemes.light.onGoldenContainer,
      contrastText: md3ThemeJson.schemes.light.onGoldenContainer,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes.dark.onGoldenContainer,
        main: md3ThemeJson.schemes.dark.onGoldenContainer,
        dark: md3ThemeJson.schemes.dark.onGoldenContainer,
        contrastText: md3ThemeJson.schemes.dark.onGoldenContainer,
      }),
    },
    golden: {
      light: md3ThemeJson.schemes.light.golden,
      main: md3ThemeJson.schemes.light.golden,
      dark: md3ThemeJson.schemes.light.golden,
      contrastText: md3ThemeJson.schemes.light.onGolden,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes.dark.golden,
        main: md3ThemeJson.schemes.dark.golden,
        dark: md3ThemeJson.schemes.dark.golden,
        contrastText: md3ThemeJson.schemes.dark.onGolden,
      }),
    },
    onGolden: {
      light: md3ThemeJson.schemes.light.onGolden,
      main: md3ThemeJson.schemes.light.onGolden,
      dark: md3ThemeJson.schemes.light.onGolden,
      contrastText: md3ThemeJson.schemes.light.onGolden,
      ...(mode === "dark" && {
        light: md3ThemeJson.schemes.dark.onGolden,
        main: md3ThemeJson.schemes.dark.onGolden,
        dark: md3ThemeJson.schemes.dark.onGolden,
        contrastText: md3ThemeJson.schemes.dark.onGolden,
      }),
    },
    text: {
      primary: md3ThemeJson.schemes.light.onBackground,
      secondary: md3ThemeJson.schemes.light.onSurface,
      ...(mode === "dark" && {
        primary: md3ThemeJson.schemes.dark.onBackground,
        secondary: md3ThemeJson.schemes.dark.onSurface,
      }),
    },
    action: {
      active: md3ThemeJson.coreColors.primary,
      hover: md3ThemeJson.schemes.light.secondary,
      selected: md3ThemeJson.schemes.light.primaryContainer,
      disabled: md3ThemeJson.schemes.light.outline,
      disabledBackground: md3ThemeJson.schemes.light.surfaceVariant,
      ...(mode === "dark" && {
        active: md3ThemeJson.coreColors.primary,
        hover: md3ThemeJson.schemes.dark.secondary,
        selected: md3ThemeJson.schemes.dark.primaryContainer,
        disabled: md3ThemeJson.schemes.dark.outline,
        disabledBackground: md3ThemeJson.schemes.dark.surfaceVariant,
      }),
    },
    divider:
      mode === "dark"
        ? md3ThemeJson.schemes.dark.outline
        : md3ThemeJson.schemes.light.outline,
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
});

export default function getAppTheme(mode: PaletteMode): ThemeOptions {
  return {
    ...getDesignTokens(mode),
  };
}
