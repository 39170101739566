import * as React from "react";
import { styled, Theme, useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, Container } from "@mui/material";

interface ThemedAccordionProps extends AccordionProps {
  theme: Theme;
}

const Accordion = styled(({ theme, ...props }: ThemedAccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&::before": {
    display: "none",
  },
}));

const faqs = [
  {
    question: "What is PiTrade?",
    answer:
      "PiTrade is a copy trading platform designed to provide users with a seamless and powerful trading experience. It caters to both novice and experienced traders, offering a variety of tools and resources to support successful trading.",
  },
  {
    question: "How does copy trading work on PiTrade?",
    answer:
      "Copy trading allows users to automatically replicate the trades of experienced traders. When you follow a trader, any trades they execute will be copied to your account proportionally, allowing you to benefit from their expertise and strategies.",
  },
  {
    question: "Do I need any prior trading experience to use PiTrade?",
    answer:
      "No, you do not need prior trading experience to use PiTrade. The platform is designed to be user-friendly and provides ample educational resources to help beginners get started.",
  },
  {
    question: "How do I create an account on PiTrade?",
    answer:
      "To create an account, simply download the PiTrade app, click on Sign Up, and follow the prompts to enter your personal information and set up your profile.",
  },
  {
    question:
      "Who are strategizers? What are the criterias to be a strategizer?",
    answer:
      "Strategizers are experienced traders on the PiTrade platform who have demonstrated exceptional trading skills and consistent success in the market. Currently, we are not onboarding new strategizers, but those interested in joining can email their past returns to support@pioneerinvesting.com to request consideration.",
  },
  {
    question: "How do I choose which trader to copy?",
    answer:
      "PiTrade provides detailed performance metrics, risk levels, and trading styles for each trader. You can use these metrics, along with our comprehensive screeners, to select a trader that aligns with your investment goals and risk tolerance.",
  },
  {
    question: "What happens if the trader I am copying makes a loss?",
    answer:
      "If the trader you are copying makes a loss, your account will reflect the same proportional loss. It is important to diversify your portfolio and manage your risk by not relying solely on one trader.",
  },
  {
    question: "Can I set my own risk management parameters?",
    answer:
      "Yes, PiTrade allows you to set your own risk management parameters, including stop-loss orders and investment limits, to protect your capital and manage your risk exposure.",
  },
  {
    question: "How are the performance fees calculated?",
    answer:
      "Performance fees are typically calculated as a percentage of the profits generated from the trades executed by the trader you are copying. These fees are only charged if the trader makes a profit.",
  },
  {
    question: "What fees does the PiTrade charge?",
    answer:
      "PiTrade charges a small fee for its copy trading services. The fee structure can include performance fees, spread fees, or subscription fees depending on the specific trader and your account type. Please refer to the platforms fee schedule for detailed information.",
  },
  {
    question: "What should I do if I encounter an issue or have a question?",
    answer:
      "If you encounter any issues or have questions, you can contact PiTrade’s customer support team through the app’s support section. Our support team is available to assist you with any inquiries or technical difficulties.",
  },
];

const FAQ = () => {
  const [expanded, setExpanded] = React.useState<string | false>("");
  const theme = useTheme();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.surface?.main,
        marginTop: "2rem",
        paddingBottom: "4rem",
      }}
    >
      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          color: theme.palette.surface?.contrastText,
          marginBottom: "2rem",
          backgroundPosition: "center",
          padding: "2rem 0",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Frequently Asked Questions
        </Typography>
      </Box>
      <Container maxWidth="md">
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            theme={theme}
            sx={{
              marginBottom: "1rem",
            }}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <MuiAccordionSummary
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
              expandIcon={
                expanded === `panel${index}` ? (
                  <RemoveIcon color="primary" />
                ) : (
                  <AddIcon color="primary" />
                )
              }
              sx={{
                backgroundColor: theme.palette.surfaceVariant?.main,
              }}
            >
              <Typography
                variant="body1"
                color={theme.palette.surface?.contrastText}
              >
                {faq.question}
              </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails
              sx={{
                backgroundColor: theme.palette.surface?.main,
              }}
            >
              <Typography
                variant="body1"
                sx={{ textAlign: "left" }}
                color={theme.palette.surface?.contrastText}
              >
                {faq.answer}
              </Typography>
            </MuiAccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

export default FAQ;
