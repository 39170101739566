import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Avatar,
  Divider,
} from "@mui/material";
import { Facebook, Instagram, LinkedIn } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import PiInvLogo from "./PiInvLogo";
import { md3ThemeJson } from "../../Theme";

const Footer = () => {
  return (
    <>
      <Divider />
      <Box
        sx={{
          color: md3ThemeJson.schemes.dark.onSurface,
          backgroundColor: md3ThemeJson.schemes.dark.surface,
          padding: "3rem 0.5rem",
        }}
      >
        <Container>
          <Typography variant="body2" sx={{ textAlign: "left" }}>
            <PiInvLogo />
          </Typography>

          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
            item
            xs={12}
            md={3}
          >
            <Typography
              variant="body2"
              sx={{ padding: "1rem 0", textAlign: "left" }}
            >
              Pioneer Investing offers PiTrade, a copy-trading application
              designed to provide users with a seamless and powerful trading
              experience. The application caters to both novice and experienced
              traders.
            </Typography>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", mb: "1rem" }}>
            <Typography variant="subtitle1" sx={{ alignSelf: "center" }}>
              Follow Us :
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                // justifyContent: "space-evenly",
                gap: "0.5rem",
              }}
            >
              <Avatar sx={{ bgcolor: "inherit" }}>
                <IconButton
                  component="a"
                  href="https://www.facebook.com/pioneerinvesting"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#1877f2" }}
                >
                  <Facebook />
                </IconButton>
              </Avatar>
              <Avatar sx={{ bgcolor: "inherit" }}>
                <IconButton
                  component="a"
                  href="https://www.instagram.com/pioneerinvestinginc"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#e02d69" }}
                >
                  <Instagram />
                </IconButton>
              </Avatar>
              <Avatar sx={{ bgcolor: "inherit" }}>
                <IconButton
                  component="a"
                  href="https://www.linkedin.com/company/pioneer-investing"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#007ebb" }}
                >
                  <LinkedIn />
                </IconButton>
              </Avatar>
              <Avatar sx={{ bgcolor: "inherit" }}>
                <IconButton
                  component="a"
                  href="https://x.com/PioneerInvestin"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "white" }}
                >
                  <XIcon />
                </IconButton>
              </Avatar>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="button" sx={{ mb: 2, fontWeight: "bold" }}>
                COMPANY
              </Typography>
              <Typography variant="caption">
                <Link
                  href="/aboutus"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  About us
                </Link>
                <Link
                  href="/pricing"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  Pricing
                </Link>
                <Link
                  href="career"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  Career
                </Link>
                <Link
                  href="/faq"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  FAQ
                </Link>
                <Link
                  href="/support"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  Support
                </Link>
              </Typography>
            </Box>
            <Box sx={{ textAlign: "left" }}>
              <Typography variant="button" sx={{ mb: 2, fontWeight: "bold" }}>
                LEGAL
              </Typography>
              <Typography variant="caption">
                <Link
                  href="/termsandconditions"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  Terms and Conditions
                </Link>
                <Link
                  href="/privacypolicy"
                  color="inherit"
                  underline="hover"
                  sx={{ display: "block", mb: 1 }}
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          </Box>
          <Typography variant="caption" sx={{ mt: 2, textAlign: "center" }}>
            {`© ${new Date().getFullYear()}. All rights reserved.`}
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
