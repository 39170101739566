import React from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import { md3ThemeJson } from "../../Theme";

const AboutUs: React.FC = () => {
  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: md3ThemeJson.schemes.dark.background,
      }}
    >
      <Box sx={{ padding: 3 }}>
        <ConstructionIcon sx={{ fontSize: 80, color: "warning.main" }} />
        <Typography variant="h3" component="h1" sx={{ mt: 2 }}>
          Under Construction
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 2, color: "text.secondary" }}>
          This page is currently being worked on. Please check back later!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={handleGoBack}
        >
          Go Back
        </Button>
      </Box>
    </Container>
  );
};

export default AboutUs;
