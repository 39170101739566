import { Theme } from "@mui/material";
import { ElementTypes } from "../model/enums/element";

const displayUtils = {
  rgbToRgba: (rgbString: string | undefined, opacity: number): string => {
    const regex =
      /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*([01]?\.?\d*))?\)$/;
    const matches = rgbString?.match(regex);
    if (!matches) {
      throw new Error("Invalid RGB or RGBA color format");
    }
    // Extract RGB values and existing alpha if present
    const [red, green, blue] = matches.slice(1);
    // Construct the RGBA string using the specified opacity, only use alpha if opacity is undefined
    const rgbaString = `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    return rgbaString;
  },

  hexToRGBA: (hex: string | undefined, opacity: number): string => {
    // Remove the hash at the front if it's there
    hex = hex?.replace("#", "") || "";
    // Parse the hex string into rgb values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  },

  getGradientProperties: (element: ElementTypes, theme: Theme): object => {
    switch (element) {
      case ElementTypes.TYPOGRAPHY:
        return {
          background: `linear-gradient(45deg, ${theme.palette.surface?.contrastText}, ${theme.palette.surface?.contrastText})`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        };
      default:
        return {};
    }
  },
};

export default displayUtils;
