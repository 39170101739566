import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./styles/main.css";
import Layout from "./layouts/Layout";
import Home from "./home/Home";
import Strategizers from "./pages/Strategizers";
import { PaletteMode } from "@mui/material";
import BlogsHome from "./blogs/BlogsHome";
import BlogContentPage from "./blogs/BlogContentPage";
import FAQ from "./home/FAQ";
import AboutUs from "./pages/AboutUs";
import Pricing from "./pages/Pricing";
import Career from "./pages/Career";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Support from "./pages/Support";

interface DesktopMainProps {
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
}

function DesktopMain({ setMode }: DesktopMainProps) {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout setMode={setMode} />}>
        <Route path="" element={<Home />} />
        <Route path="blogs" element={<BlogsHome />} />
        <Route path="blogs/:title" element={<BlogContentPage />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="strategizers" element={<Strategizers />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="career" element={<Career />} />
        <Route path="support" element={<Support />} />
        <Route path="termsandconditions" element={<TermsAndConditions />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Home />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
}

export default DesktopMain;
