import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  SvgIcon,
  Skeleton,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import amplifyService from "../../../src/services/amplifyService";
import {
  BlogData,
  GetBlogsByCategoryRequest,
  GetAllBlogsRequest,
} from "../../model/services/amplify";
import { formatDistanceToNow } from "date-fns";
import { useTheme } from "@mui/material/styles";

const BlogsHome: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [displayedCategory, setDisplayedCategory] =
    useState<string>("Categories");
  const [categories, setCategories] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [blogs, setBlogs] = useState<BlogData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const blogsPerPage = 12;

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await amplifyService.getBlogCategories();
      if (response.categories) {
        setCategories([...response.categories]);
      } else if (response.error) {
        console.error(response.error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory === "all") {
      fetchAllBlogs(null);
    } else {
      fetchBlogsByCategory(null);
    }
  }, [selectedCategory]);

  const fetchAllBlogs = async (pageToken: string | null) => {
    setLoading(true);
    const request: GetAllBlogsRequest = {
      limit: blogsPerPage,
      nextPage: pageToken,
    };
    const response = await amplifyService.getAllBlogs(request);
    if (response.results) {
      setBlogs((prevBlogs) => [
        ...(pageToken ? prevBlogs : []),
        ...(response.results ?? []),
      ]);
      setNextPage(response.nextPage || null);
    } else if (response.error) {
      console.error(response.error);
    }
    setLoading(false);
  };

  const fetchBlogsByCategory = async (pageToken: string | null) => {
    if (selectedCategory === "all") return fetchAllBlogs(pageToken);

    setLoading(true);
    const request: GetBlogsByCategoryRequest = {
      limit: blogsPerPage,
      category: selectedCategory,
      nextPage: pageToken,
    };
    const response = await amplifyService.getBlogsByCategory(request);
    if (response.results) {
      setBlogs((prevBlogs) => [
        ...(pageToken ? prevBlogs : []),
        ...(response.results ?? []),
      ]);
      setNextPage(response.nextPage || null);
    } else if (response.error) {
      console.error(response.error);
    }
    setLoading(false);
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category === "All" ? "all" : category);
    setDisplayedCategory(category);
    setPage(1);
    setBlogs([]);
    setNextPage(null);
    handleMenuClose();
  };

  const handlePageChange = async (
    event: ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    if (nextPage) {
      selectedCategory === "all"
        ? fetchAllBlogs(nextPage)
        : fetchBlogsByCategory(nextPage);
    }
  };

  const displayedBlogs = blogs.slice(
    (page - 1) * blogsPerPage,
    page * blogsPerPage,
  );

  return (
    <Box sx={{ margin: "0 2rem", padding: "0 1.5rem" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="body1" sx={{ mr: 1 }}>
            {displayedCategory}
          </Typography>
          <IconButton onClick={handleMenuOpen}>
            <SvgIcon component={ExpandMore} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {categories.map((category) => (
              <MenuItem
                key={category}
                onClick={() => handleCategorySelect(category)}
              >
                {category}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="flex-start"
        sx={{
          "& > *": {
            flexBasis: "calc(33.333% - 1rem)",
            maxWidth: "calc(33.333% - 1rem)",
          },
        }}
      >
        {loading
          ? Array.from(new Array(blogsPerPage)).map((_, index) => (
              <Card
                key={index}
                sx={{
                  backgroundColor: theme.palette.surfaceVariant?.main,
                  borderRadius: "16px",
                  margin: "1rem auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton variant="rectangular" width="100%" height={180} />
                <CardContent>
                  <Skeleton
                    variant="text"
                    width="100%"
                    sx={{ fontSize: "2rem" }}
                  />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Skeleton
                      variant="text"
                      width="40%"
                      sx={{ marginTop: 2, textAlign: "center" }}
                    />
                  </Box>
                </CardContent>
              </Card>
            ))
          : displayedBlogs.map((blog, index) => {
              const modifiedTitle = encodeURIComponent(blog.title);

              return (
                <Card
                  key={index}
                  sx={{
                    borderRadius: "16px",
                    position: "relative",
                    "&:hover": { transform: "scale(1.05)" },
                    transition: "transform 0.2s",
                    margin: "1rem auto",
                    cursor: "pointer",
                    backgroundColor: theme.palette.surfaceContainerLow?.main,
                    textDecoration: "none",
                    "& a": {
                      textDecoration: "none",
                      color: "inherit",
                    },
                  }}
                  component="a"
                  href={`/blogs/${modifiedTitle}`}
                >
                  <CardMedia
                    component="img"
                    height="180"
                    image={blog.imageUrl}
                    alt={blog.title}
                    sx={{ opacity: 0.9 }}
                  />
                  <Box
                    sx={{
                      textDecoration: "none",
                      position: "absolute",
                      top: 8,
                      right: 8,
                      backgroundColor: theme.palette.surfaceContainerLow?.main,
                      color: theme.palette.surfaceContainerLow?.contrastText,
                      borderRadius: "16px",
                      padding: "0 8px",
                      "&:hover": {
                        backgroundColor: theme.palette.primaryContainer?.main,
                        color: theme.palette.primaryContainer?.contrastText,
                      },
                    }}
                    component="a"
                  >
                    {blog.category}
                  </Box>
                  <CardContent sx={{ textDecoration: "none" }}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{
                        textDecoration: "none",
                        color: "inherit",
                        textAlign: "center",
                      }}
                    >
                      {blog.title}
                    </Typography>{" "}
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      {blog.description}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        marginTop: 2,
                        textAlign: "center",
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      {formatDistanceToNow(new Date(blog.createdDate), {
                        addSuffix: true,
                      })}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
      </Box>
      <Pagination
        count={Math.ceil(blogs.length / blogsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2, mb: 4, display: "flex", justifyContent: "center" }}
      />
    </Box>
  );
};

export default BlogsHome;
