import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import AirIcon from "@mui/icons-material/Air";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import BalanceIcon from "@mui/icons-material/Balance";
import SchoolIcon from "@mui/icons-material/School";
import useIntersectionObserver from "../../utils/UseIntersectionObserver";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.surfaceContainerLow?.main,
  position: "relative",
  overflow: "visible",
  borderRadius: "1rem",
  transition: "transform 0.3s ease-in-out",
}));

const SquareCardBorderTop = styled(StyledCard)(({ theme }) => ({
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-10px",
    left: "-10px",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primaryContainer?.main})`,
    zIndex: "-1",
    transition: "opacity 0.3s ease-in-out",
  },
}));

const SquareCardBorderBottom = styled(StyledCard)(({ theme }) => ({
  "&::before": {
    content: '""',
    position: "absolute",
    top: "10px",
    right: "-10px",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    background: `linear-gradient(45deg, ${theme.palette.primaryContainer?.main}, ${theme.palette.primary.main})`,
    zIndex: "-1",
    transition: "opacity 0.3s ease-in-out",
  },
}));

const Cards: React.FC = () => {
  const theme = useTheme();

  const [section1HasAppeared, setSection1HasAppeared] = useState(false);
  const [section3HasAppeared, setSection3HasAppeared] = useState(false);
  const [section4HasAppeared, setSection4HasAppeared] = useState(false);

  const strategizerImage =
    theme.palette.mode === "dark"
      ? "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/strategizer_light.png"
      : "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/strategizer_dark.png";

  const investingImage1 =
    theme.palette.mode === "dark"
      ? "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/home_1_light.png"
      : "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/home_1_dark.png";

  const investingImage2 =
    theme.palette.mode === "dark"
      ? "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/home_2_light.png"
      : "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/home_2_dark.png";

  const investingImage3 =
    theme.palette.mode === "dark"
      ? "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/home_3_light.png"
      : "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/home_3_dark.png";

  const { isVisible: isSection1Visible, elementRef: section1Ref } =
    useIntersectionObserver({ threshold: 0.1 });
  const { isVisible: isSection3Visible, elementRef: section3Ref } =
    useIntersectionObserver({ threshold: 0.1 });
  const { isVisible: isSection4Visible, elementRef: section4Ref } =
    useIntersectionObserver({ threshold: 0.1 });

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the far right on component mount
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, []);

  useEffect(() => {
    if (isSection1Visible && !section1HasAppeared) {
      setSection1HasAppeared(true);
    }
  }, [isSection1Visible, section1HasAppeared]);

  useEffect(() => {
    if (isSection3Visible && !section3HasAppeared) {
      setSection3HasAppeared(true);
    }
  }, [isSection3Visible, section3HasAppeared]);

  useEffect(() => {
    if (isSection4Visible && !section4HasAppeared) {
      setSection4HasAppeared(true);
    }
  }, [isSection4Visible, section4HasAppeared]);

  return (
    <Box>
      {/* Section 1 */}
      <Box
        ref={section1Ref}
        sx={{
          height: "100vh",
          width: "100%",
          maxWidth: "100%",
          opacity: section1HasAppeared ? 1 : 0,
          transform: section1HasAppeared
            ? "translateX(0)"
            : "translateX(-50px)",
          transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          backgroundColor: theme.palette.surface?.main,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
              width: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <img
              src={investingImage3}
              alt="investingImage3"
              style={{
                height: "400px",
                maxWidth: "49wh",
                transform: section1HasAppeared
                  ? "translateX(0)"
                  : "translateX(+150px)",
                transition: "transform 0.6s ease-out",
                position: "relative",
                top: "-2.5rem",
                left: "4rem",
              }}
            />
            <img
              src={strategizerImage}
              alt="strategizerImage"
              style={{
                height: "400px",
                maxWidth: "49wh",
                position: "relative",
                top: "2.5rem",
                right: "3rem",
                transform: section1HasAppeared
                  ? "translateX(0)"
                  : "translateX(+150px)",
                transition: "transform 0.6s ease-out",
              }}
            />
          </Box>
          <Box
            sx={{
              height: "30vh",
              alignContent: "center",
              textAlign: "center",
              width: "100%",
              padding: "0 1rem",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                lineHeight: "3rem",
                color: theme.palette.surface?.contrastText,
              }}
            >
              Unlock the power of{" "}
              <span style={{ whiteSpace: "nowrap" }}>smart investing</span>
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                lineHeight: "1.5625rem",
                marginTop: "1.5rem",
              }}
            >
              Copy the moves of top traders and watch your portfolio grow
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* End of Section 1 */}
      {/* Section 2 */}
      <Box
        sx={{
          // height: "100vh",
          padding: "5rem 1rem",
          transform: "translateY(0)",
          backgroundColor: theme.palette.surfaceContainerLow?.main,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            lineHeight: "3rem",
            textAlign: "center",
            color: theme.palette.surface?.contrastText,
          }}
        >
          Grow with certainity - Is it for you?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "25px",
            pt: "5rem",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "25px",
              width: "100%",
            }}
          >
            <SquareCardBorderTop
              sx={{
                width: "40%",
                textAlign: "center",
              }}
            >
              <CardContent>
                <CandlestickChartIcon
                  sx={{ fontSize: "40px", color: theme.palette.primary.main }}
                />
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ marginTop: "0.5rem", lineHeight: "1.2" }}
                >
                  Expert Strategies
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: " 1rem" }}
                >
                  Leverage proven strategies from seasoned professionals
                </Typography>
              </CardContent>
            </SquareCardBorderTop>

            <StyledCard sx={{ width: "40%", textAlign: "center" }}>
              <CardContent>
                <HourglassFullIcon
                  sx={{ fontSize: "40px", color: theme.palette.primary.main }}
                />
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ marginTop: "0.5rem", lineHeight: "1.2" }}
                >
                  Time Saving
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: "1rem" }}
                >
                  Automate your trades and save valuable time
                </Typography>
              </CardContent>
            </StyledCard>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "25px",
              width: "100%",
            }}
          >
            <StyledCard sx={{ width: "40%", textAlign: "center" }}>
              <CardContent>
                <PriceCheckIcon
                  sx={{ fontSize: "40px", color: theme.palette.primary.main }}
                />
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ marginTop: "0.5rem", lineHeight: "1.2" }}
                >
                  Easy to Begin
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: " 1rem" }}
                >
                  Start trading with minimal capital and expertise
                </Typography>
              </CardContent>
            </StyledCard>
            <StyledCard
              sx={{
                width: "40%",
                textAlign: "center",
              }}
            >
              <CardContent>
                <AirIcon
                  sx={{ fontSize: "40px", color: theme.palette.primary.main }}
                />
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ marginTop: "0.5rem", lineHeight: "1.2" }}
                >
                  Invest Diversely
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: " 1rem" }}
                >
                  Diversify your portfolio across various asset classes
                </Typography>
              </CardContent>
            </StyledCard>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "25px",
              width: "100%",
            }}
          >
            <StyledCard
              sx={{
                width: "40%",
                textAlign: "center",
              }}
            >
              <CardContent>
                <BalanceIcon
                  sx={{
                    fontSize: "40px",
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ marginTop: "0.5rem", lineHeight: "1.2" }}
                >
                  Risk Management
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: " 1rem" }}
                >
                  Benefit from advanced risk management tools
                </Typography>
              </CardContent>
            </StyledCard>

            <SquareCardBorderBottom
              sx={{
                width: "40%",
                textAlign: "center",
              }}
            >
              <CardContent>
                <LeaderboardIcon
                  sx={{ fontSize: "40px", color: theme.palette.primary.main }}
                />
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ marginTop: "0.5rem", lineHeight: "1.2" }}
                >
                  Consistency
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: " 1rem" }}
                >
                  Achieve steady returns with successful strategizers
                </Typography>
              </CardContent>
            </SquareCardBorderBottom>
          </Box>
        </Box>
      </Box>{" "}
      {/* End of Section 2 */}
      {/* Section 3 */}
      <Box
        ref={section3Ref}
        sx={{
          height: "100vh",
          width: "100%",
          backgroundColor: theme.palette.surface?.main,
          opacity: section3HasAppeared ? 1 : 0,
          transform: section3HasAppeared
            ? "translateX(0)"
            : "translateX(-50px)",
          transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 4rem)",
            width: "100%",
            overflow: "hidden",
          }}
        >
          {/* Upside content start */}
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              transform: section3HasAppeared
                ? "translateX(0)"
                : "translateX(+150px)",
              transition: "transform 0.6s ease-out",
              width: "100%",
            }}
          >
            <Box
              sx={{
                padding: "3rem 0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.palette.surfaceContainerLow?.main,
                borderRadius: "16px",
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <Box>
                <SchoolIcon
                  sx={{
                    height: "100px",
                    width: "auto",
                  }}
                />
              </Box>
              <Box>
                <Typography variant="h5" sx={{ textAlign: "center" }}>
                  3 Years of trading experience
                </Typography>
              </Box>
              <Box
                ref={scrollContainerRef} // Attach the ref to this container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 3,
                  marginTop: "2rem",
                  width: "100%",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  padding: "0 10px",
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For WebKit browsers (Chrome, Safari, etc.)
                  },
                }}
              >
                {/* Circular Progress for 2024 */}
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    marginBottom: "1.5rem",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={60}
                    size={100}
                    thickness={1}
                    sx={{ color: "#4caf50" }} // green color
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="div"
                      color="textSecondary"
                    >
                      2024
                    </Typography>
                    <Box>
                      <Typography variant="caption">Return: </Typography>
                      <Typography variant="caption" color="#4caf50">
                        12.6%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* Circular Progress for 2023 */}
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    marginBottom: "1.5rem",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={80}
                    size={100}
                    thickness={1}
                    sx={{ color: "#f44336" }} // red color
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="div"
                      color="textSecondary"
                    >
                      2023
                    </Typography>
                    <Box>
                      <Typography variant="caption">Return: </Typography>
                      <Typography variant="caption" color="#4caf50">
                        22.6%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* Circular Progress for 2022 */}
                <Box
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    marginBottom: "1.5rem",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={40}
                    size={100}
                    thickness={1}
                    sx={{ color: "#ff9800" }} // orange color
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      component="div"
                      color="textSecondary"
                    >
                      2022
                    </Typography>
                    <Box>
                      <Typography variant="caption">Return: </Typography>
                      <Typography variant="caption" color="#4caf50">
                        8.6%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* Repeat for other Circular Progress Components */}
              </Box>
            </Box>
          </Box>
          {/* Upside content end */}
          {/* Downside content start */}
          <Box
            sx={{
              height: "100vh",
              alignContent: "center",
              textAlign: "center",
              width: "100%",
              overflow: "hidden",
              padding: "0 1rem",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                lineHeight: "3rem",
                color: theme.palette.surface?.contrastText,
              }}
            >
              Expert strategizers for{" "}
              <span style={{ whiteSpace: "nowrap" }}>all asset classes</span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 1rem",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                  <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                    >
                      Stocks & ETFs
                    </Typography>
                  </ListItem>
                </List>
                <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                  <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                    >
                      Bonds
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                  <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                    >
                      Futures
                    </Typography>
                  </ListItem>
                </List>
                <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                  <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                    >
                      Options
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Box>
          </Box>
          {/* Downside content end */}
        </Box>
      </Box>
      {/* End of Section 3 */}
      {/* Section 4 */}
      <Box
        ref={section4Ref}
        sx={{
          height: "100vh",
          backgroundColor: theme.palette.surfaceContainerLow?.main,
          opacity: section4HasAppeared ? 1 : 0,
          transform: section4HasAppeared
            ? "translateX(0)"
            : "translateX(-50px)",
          transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={investingImage2}
              alt="investingImage2"
              style={{
                height: "400px",
                width: "auto",
                maxWidth: "100%",
                transform: section4HasAppeared
                  ? "translateX(0)"
                  : "translateX(+150px)",
                transition: "transform 0.6s ease-out",
              }}
            />
          </Box>
          <Box
            sx={{
              height: "40vh",
              alignContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                alignContent: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  lineHeight: "3rem",
                  color: theme.palette.surface?.contrastText,
                }}
              >
                Explore tools for investing
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "0 1rem",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                    <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                      >
                        Theme based investing
                      </Typography>
                    </ListItem>
                  </List>
                  <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                    <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                      >
                        Screener
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                    <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                      >
                        Smart indicators
                      </Typography>
                    </ListItem>
                  </List>
                  <List sx={{ listStyleType: "disc", paddingLeft: "1.5rem" }}>
                    <ListItem sx={{ display: "list-item", paddingLeft: 0 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ lineHeight: "1.5625rem", marginTop: "1.5rem" }}
                      >
                        Other
                      </Typography>
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* End of Section 4 */}
    </Box>
  );
};

export default Cards;
