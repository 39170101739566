import React from "react";

const PlayStoreLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <linearGradient
      id="AraffhWwwEqZfgFEBZFoqa_L1ws9zn2uD01_gr1"
      x1="18.102"
      x2="25.297"
      y1="3.244"
      y2="34.74"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#35ab4a"></stop>
      <stop offset=".297" stopColor="#31a145"></stop>
      <stop offset=".798" stopColor="#288739"></stop>
      <stop offset="1" stopColor="#237a33"></stop>
    </linearGradient>
    <path
      fill="url(#AraffhWwwEqZfgFEBZFoqa_L1ws9zn2uD01_gr1)"
      d="M13.488,4.012C10.794,2.508,7.605,3.778,6.45,6.323L24.126,24l9.014-9.014L13.488,4.012z"
    ></path>
    <linearGradient
      id="AraffhWwwEqZfgFEBZFoqb_L1ws9zn2uD01_gr2"
      x1="19.158"
      x2="21.194"
      y1="23.862"
      y2="66.931"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#f14e5d"></stop>
      <stop offset=".499" stopColor="#ea3d4f"></stop>
      <stop offset="1" stopColor="#e12138"></stop>
    </linearGradient>
    <path
      fill="url(#AraffhWwwEqZfgFEBZFoqb_L1ws9zn2uD01_gr2)"
      d="M33.14,33.014L24.126,24L6.45,41.677 c1.156,2.546,4.345,3.815,7.038,2.312L33.14,33.014z"
    ></path>
    <linearGradient
      id="AraffhWwwEqZfgFEBZFoqc_L1ws9zn2uD01_gr3"
      x1="32.943"
      x2="36.541"
      y1="14.899"
      y2="43.612"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stopColor="#ffd844"></stop>
      <stop offset=".519" stopColor="#ffc63f"></stop>
      <stop offset="1" stopColor="#ffb03a"></stop>
    </linearGradient>
    <path
      fill="url(#AraffhWwwEqZfgFEBZFoqc_L1ws9zn2uD01_gr3)"
      d="M41.419,28.393 c1.72-0.96,2.58-2.676,2.581-4.393c-0.001-1.717-0.861-3.434-2.581-4.393l-8.279-4.621L24.126,24l9.014,9.014L41.419,28.393z"
    ></path>
    <linearGradient
      id="AraffhWwwEqZfgFEBZFoqd_L1ws9zn2uD01_gr4"
      x1="13.853"
      x2="15.572"
      y1="5.901"
      y2="42.811"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".003" stopColor="#0090e6"></stop>
      <stop offset="1" stopColor="#0065a0"></stop>
    </linearGradient>
    <path
      fill="url(#AraffhWwwEqZfgFEBZFoqd_L1ws9zn2uD01_gr4)"
      d="M6.45,6.323C6.168,6.948,6,7.652,6,8.408 v31.179c0,0.761,0.164,1.463,0.45,2.09l17.674-17.68L6.45,6.323z"
    ></path>
  </svg>
);

export default PlayStoreLogo;
