import { get, post } from "aws-amplify/api";
import {
  AllBlogs,
  GetAllBlogsRequest,
  GetAllBlogsResponse,
  GetBlogContentRequest,
  GetBlogContentRespone,
  GetBlogsByCategoryRequest,
  GetBlogsByCategoryResponse,
  GetBlogsCategoryResponse,
  SubmitCustomerQueryRequest,
} from "../model/services/amplify";

const amplifyService = {
  getAllBlogs: async (
    request: GetAllBlogsRequest,
  ): Promise<GetAllBlogsResponse> => {
    try {
      const queryParams: any = {
        limit: request.limit.toString(),
      };
      if (request.nextPage) {
        queryParams["nextPage"] = request.nextPage;
      }
      const restOperation = get({
        apiName: "blogsApi",
        path: "/getAllBlogs",
        options: {
          queryParams: queryParams,
        },
      });
      const response = await restOperation.response;
      if (response.statusCode === 200) {
        const jsonResponse =
          (await response.body.json()) as unknown as AllBlogs;
        return {
          results: jsonResponse.results,
          nextPage: jsonResponse.nextPage,
          error: null,
        };
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Caught error in calling getAllBlogs", error);
      return {
        results: null,
        nextPage: null,
        error: error instanceof Error ? error : new Error(String(error)),
      };
    }
  },

  getBlogCategories: async (): Promise<GetBlogsCategoryResponse> => {
    try {
      const restOperation = get({
        apiName: "blogsApi",
        path: "/getCategories",
      });
      const response = await restOperation.response;
      if (response.statusCode === 200) {
        const jsonResponse = (await response.body.json()) as unknown as {
          results: string[];
        };
        return {
          categories: jsonResponse.results,
          error: null,
        };
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Caught error in calling getBlogCategories", error);
      return {
        categories: null,
        error: error instanceof Error ? error : new Error(String(error)),
      };
    }
  },

  getBlogsByCategory: async (
    request: GetBlogsByCategoryRequest,
  ): Promise<GetBlogsByCategoryResponse> => {
    try {
      const queryParams: any = {
        limit: request.limit.toString(),
        category: request.category,
      };
      if (request.nextPage) {
        queryParams["nextPage"] = request.nextPage;
      }
      const restOperation = get({
        apiName: "blogsApi",
        path: "/getBlogsByCategory",
        options: {
          queryParams: queryParams,
        },
      });
      const response = await restOperation.response;
      if (response.statusCode === 200) {
        const jsonResponse =
          (await response.body.json()) as unknown as AllBlogs;
        return {
          results: jsonResponse.results,
          nextPage: jsonResponse.nextPage,
          error: null,
        };
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Caught error in calling getBlogsByCategory", error);
      return {
        results: null,
        nextPage: null,
        error: error instanceof Error ? error : new Error(String(error)),
      };
    }
  },

  getBlogContent: async (
    request: GetBlogContentRequest,
  ): Promise<GetBlogContentRespone> => {
    try {
      const restOperation = get({
        apiName: "blogsApi",
        path: "/getContent",
        options: {
          queryParams: {
            blogId: request.id,
          },
        },
      });
      const response = await restOperation.response;
      if (response.statusCode === 200) {
        const jsonResponse = (await response.body.json()) as unknown as {
          content: string;
        };
        return {
          content: jsonResponse.content,
          error: null,
        };
      } else {
        throw new Error("Unknown error occurred");
      }
    } catch (error) {
      console.error("Caught error in calling getAllBlogs", error);
      return {
        content: null,
        error: error instanceof Error ? error : new Error(String(error)),
      };
    }
  },

  submitCustomerQuery: async (
    request: SubmitCustomerQueryRequest,
  ): Promise<boolean> => {
    try {
      const restOperation = post({
        apiName: "supportApi",
        path: "/submitCustomerQuery",
        options: {
          body: {
            emailId: request.emailId,
            firstName: request.firstName,
            lastName: request.lastName,
            message: request.message,
          },
          headers: {
            "Content-Type": "application/json",
          },
        },
      });
      const response = await restOperation.response;
      return response.statusCode === 200;
    } catch (error) {
      console.error("Caught error in submitting customer query", error);
      return false;
    }
  },
};

export default amplifyService;
