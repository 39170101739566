import React from "react";
import { Box, Typography, Container, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./MobileHeroBanner.css";
import {
  Pagination,
  Navigation,
  EffectCoverflow,
  Autoplay,
} from "swiper/modules";
import displayUtils from "../../utils/displayUtils";

const slides = [
  {
    image:
      "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/hero_slider_2.webp",
    title: "PiTrade",
    subtitle: "Limited time or expertise? No problem!",
    additionalText:
      "Copy top strategizers in real-time across all asset classes and boost your return",
  },
  {
    image:
      "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/hero_slider_3.webp",
    title: "Strategizers",
    subtitle: "Invest in knowledge, experience and consistency",
    additionalText:
      "Meet out top strategizers with a proven record of generating consistent profits",
  },
  {
    image:
      "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/hero_slider_1.webp",
    title: "Smart Investing",
    subtitle: "Investing is as much an art as it is a science",
    additionalText:
      "Simplified and diversified investing with experts, even for math haters",
  },
  {
    image:
      "https://pioneer-investing-accessible-image.s3.us-east-2.amazonaws.com/website/hero_slider_4.webp",
    title: "Join the 1%",
    subtitle: "Smart investing in the Best of the Best",
    additionalText:
      "Wealth creation is an art, master it by letting your money work for you",
  },
];

const HeroBanner: React.FC = () => {
  const theme = useTheme();

  React.useEffect(() => {
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      theme.palette.inverseSurface?.main || "",
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-active-color",
      theme.palette.primary.main,
    );
    document.documentElement.style.setProperty(
      "--swiper-arrow-color",
      theme.palette.primary.main,
    );
  }, [theme]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        backgroundColor: theme.palette.surface?.main,
      }}
    >
      <Swiper
        slidesPerView={1.2}
        spaceBetween={0}
        loop={true}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation, EffectCoverflow, Autoplay]}
        effect="coverflow"
        className="mySwiperMobile"
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "calc(85vh - 6rem - 80px)",
                margin: "20px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: displayUtils.hexToRGBA(
                    theme.palette.surfaceContainerLow?.main,
                    0.8,
                  ),
                  zIndex: 1,
                },
                "& > *": {
                  position: "relative",
                  zIndex: 2,
                },
                backgroundImage: `url(${slide.image})`,
              }}
            >
              <Container>
                <Typography
                  variant={"h4"}
                  className="animate__animated animate__fadeInDown"
                  color={theme.palette.primaryContainer?.contrastText}
                >
                  {slide.title}
                </Typography>
                <Typography
                  variant={"h6"}
                  className="animate__animated animate__fadeInUp"
                  color={theme.palette.surface?.contrastText}
                >
                  {slide.subtitle}
                </Typography>
                {slide.additionalText && (
                  <Typography
                    variant={"body1"}
                    className="animate__animated animate__fadeInUp"
                    color={theme.palette.surface?.contrastText}
                  >
                    {slide.additionalText}
                  </Typography>
                )}
              </Container>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default HeroBanner;
