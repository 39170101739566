import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Breadcrumbs,
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Skeleton,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";
import rehypeHighlight from "rehype-highlight";
import rehypeSlug from "rehype-slug";
import "katex/dist/katex.min.css";
import amplifyService from "../../../src/services/amplifyService";

interface TocItem {
  text: string;
  id: string;
}
interface BlogData {
  title: string;
  description: string;
  createdDate: number;
  category: string;
  imageUrl: string;
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const BlogContentSkeleton: React.FC = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        {/* Left sidebar for Table of Contents */}
        <Box
          sx={{
            position: { md: "sticky", xs: "static" },
            top: "20px",
            maxHeight: { md: "calc(100vh - 40px)" },
            overflowY: "auto",
            flex: "0 0 20%",
            marginLeft: { xs: "0", md: "3rem" },
            paddingTop: "1rem",
            width: { xs: "100%", md: "20%" },
          }}
        >
          <Typography sx={{ textAlign: "left" }} variant="h6">
            <Skeleton variant="text" width="60%" />
          </Typography>
          <List>
            {Array.from(new Array(8)).map((_, index) => (
              <ListItem
                key={index}
                sx={{
                  padding: "0.5rem 0",
                }}
              >
                <Skeleton variant="text" width="80%" />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Right side content area */}
        <Box
          sx={{
            flex: "1",
            padding: "20px",
            overflowY: "auto",
            maxWidth: "100%",
            boxSizing: "border-box",
          }}
        >
          <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom>
            <Skeleton variant="text" width="100%" />
          </Typography>
          <Typography sx={{ textAlign: "center" }} variant="body1" gutterBottom>
            <Skeleton variant="text" width="100%" sx={{ mb: 3 }} />
          </Typography>
          <Box
            sx={{
              width: "70%",
              maxWidth: "100%",
              borderRadius: "16px",
              display: "block",
              margin: "0 auto",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={300}
              sx={{ mb: 3, borderRadius: "16px" }}
            />
          </Box>
          <Box
            sx={{ textAlign: "left", margin: { xs: "0 1rem", md: "0 4rem" } }}
          >
            {Array.from(new Array(10)).map((_, index) => (
              <Box key={index} sx={{ marginBottom: "20px" }}>
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="85%" />
                <Skeleton variant="text" width="70%" />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const BlogContentPage: React.FC = () => {
  const { title } = useParams<{ title: string }>();
  const modifiedtitle = title?.replace(/ /g, "%20") || "";
  const decodedTitle = decodeURIComponent(title?.replace(/%20/g, " ") || "");
  const [blog, setBlog] = useState<BlogData | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [toc, setToc] = useState<TocItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!modifiedtitle) {
      setLoading(false);
      return;
    }

    const fetchBlogContent = async () => {
      try {
        const { results } = await amplifyService.getAllBlogs({
          limit: 100,
          nextPage: null,
        });
        const selectedBlog = results?.find(
          (blog: BlogData) => blog.title === decodedTitle,
        );
        const blogDataResponse = await amplifyService.getBlogContent({
          id: modifiedtitle,
        });
        if (selectedBlog) {
          setBlog(selectedBlog);
          setContent(blogDataResponse.content);
        } else {
          setBlog(null);
        }
      } catch (error) {
        console.error("Error fetching blog content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogContent();
  }, [decodedTitle]);

  useEffect(() => {
    if (content) {
      const headers = content.match(/^##? [^\n]+/gm);
      if (headers) {
        const tocItems = headers.map((header) => {
          const cleanHeader = header.replace(/^##? /, "").trim();
          const id = cleanHeader
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, "");
          return {
            text: cleanHeader,
            id,
          };
        });
        setToc(tocItems);
      }
    }
  }, [content]);

  const handleTocClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const offset =
        element.getBoundingClientRect().top +
        window.scrollY -
        window.innerHeight / 4;
      window.scrollTo({ top: offset, behavior: "smooth" });
    }
  };

  if (loading) {
    return <BlogContentSkeleton />;
  }

  if (!blog || !content) {
    return <Typography>Blog content not found.</Typography>;
  }

  return (
    <Box>
      <Breadcrumbs sx={{ marginLeft: "3rem" }} aria-label="breadcrumb">
        <MuiLink href="/" underline="none">
          Home
        </MuiLink>
        <MuiLink href="/blogs" underline="none">
          Blogs
        </MuiLink>
        <Typography color="text.primary">{blog.title}</Typography>
      </Breadcrumbs>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        {/* Left sidebar for Table of Contents */}
        <Box
          sx={{
            position: { md: "sticky", xs: "static" },
            top: "20px",
            maxHeight: { md: "calc(100vh - 40px)" },
            overflowY: "auto",
            flex: "0 0 20%",
            marginLeft: { xs: "0", md: "3rem" },
            paddingTop: "1rem",
            width: { xs: "100%", md: "20%" },
          }}
        >
          <Typography sx={{ textAlign: "left" }} variant="h6">
            Table of Contents
          </Typography>
          <List>
            {toc.map((header, index) => (
              <ListItem
                key={index}
                sx={{
                  padding: "0.5rem 0",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  "& span": {
                    fontSize: "0.875rem",
                  },
                }}
                onClick={() => handleTocClick(header.id)}
              >
                <ListItemText primary={header.text} />
              </ListItem>
            ))}
          </List>
        </Box>
        {/* Right side content area */}
        <Box
          sx={{
            flex: "1",
            padding: "20px",
            overflowY: "auto",
            maxWidth: "100%",
            boxSizing: "border-box",
          }}
        >
          <Typography sx={{ textAlign: "center" }} variant="h4" gutterBottom>
            {blog.title}
          </Typography>
          <Typography sx={{ textAlign: "center" }} variant="body1" gutterBottom>
            {formatDate(blog.createdDate)} | {blog.category}
          </Typography>
          <Box
            sx={{
              width: "70%",
              maxWidth: "100%",
              borderRadius: "16px",
              display: "block",
              margin: "0 auto",
            }}
            component="img"
            src={blog.imageUrl}
          />
          <Box
            sx={{ textAlign: "left", margin: { xs: "0 1rem", md: "0 4rem" } }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[
                rehypeRaw,
                rehypeKatex,
                rehypeHighlight,
                rehypeSlug,
              ]}
            >
              {content}
            </ReactMarkdown>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogContentPage;
