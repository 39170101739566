import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";
import { md3ThemeJson } from "./Theme";

Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const Main = () => {
  useEffect(() => {
    // Set CSS variables on the root element
    document.documentElement.style.setProperty(
      "--body-background-color",
      md3ThemeJson.schemes.dark.background,
    );
  }, []);

  return <App />;
};

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
);

reportWebVitals();
