import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { md3ThemeJson } from "../../Theme";

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
        backgroundColor: md3ThemeJson.schemes.dark.background,
        color: md3ThemeJson.schemes.dark.onBackground,
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom>
        Privacy Policy
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to [Your Company Name]. We are committed to protecting your
          personal information and your right to privacy. This Privacy Policy
          explains what information we collect, how we use it, and what rights
          you have in relation to it.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          2. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel
          orci ut ligula congue pharetra. Integer luctus dui vel mauris cursus,
          ac efficitur leo facilisis. Proin aliquet quam et orci dictum, non
          facilisis ligula gravida.
        </Typography>
        <Typography variant="body1" paragraph>
          Aenean lacinia bibendum nulla sed consectetur. Maecenas sed diam eget
          risus varius blandit sit amet non magna.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          3. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id
          ligula porta felis euismod semper. Cras justo odio, dapibus ac
          facilisis in, egestas eget quam. Nullam quis risus eget urna mollis
          ornare vel eu leo.
        </Typography>
        <Typography variant="body1" paragraph>
          Curabitur blandit tempus porttitor. Sed posuere consectetur est at
          lobortis.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          4. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae
          elit libero, a pharetra augue. Etiam porta sem malesuada magna mollis
          euismod.
        </Typography>
        <Typography variant="body1" paragraph>
          Donec id elit non mi porta gravida at eget metus. Curabitur blandit
          tempus porttitor.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          5. Security of Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id
          dolor id nibh ultricies vehicula ut id elit. Aenean lacinia bibendum
          nulla sed consectetur.
        </Typography>
        <Typography variant="body1" paragraph>
          Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
          nibh, ut fermentum massa justo sit amet risus.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          6. Your Privacy Rights
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
          sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </Typography>
        <Typography variant="body1" paragraph>
          Nulla facilisi. Integer posuere erat a ante venenatis dapibus posuere
          velit aliquet.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum id
          ligula porta felis euismod semper. Sed posuere consectetur est at
          lobortis.
        </Typography>
        <Typography variant="body1" paragraph>
          This policy is effective as of [Date].
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions about this Privacy Policy, please contact us
          at [Contact Information].
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
