import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { md3ThemeJson } from "../../Theme";

const TermsAndConditions = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        paddingTop: "4rem",
        paddingBottom: "4rem",
        backgroundColor: md3ThemeJson.schemes.dark.background,
        color: md3ThemeJson.schemes.dark.onBackground,
      }}
    >
      <Typography variant="h3" component="h1" gutterBottom>
        Terms and Conditions
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to [Your Company Name]. These Terms and Conditions govern your
          use of our website and services. By accessing or using our services,
          you agree to comply with and be bound by these terms.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          2. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content and materials provided on this website are the property of
          [Your Company Name] and are protected by intellectual property laws.
          You may not use, reproduce, or distribute any content without our
          explicit permission.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          3. Use of Services
        </Typography>
        <Typography variant="body1" paragraph>
          You agree to use our services only for lawful purposes and in
          accordance with these Terms. You must not use our services in any way
          that could damage or impair our services or interfere with other users
          enjoyment of them.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          4. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          You are responsible for maintaining the confidentiality of your
          account information and for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account or any other breach of security.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          5. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, [Your Company Name] shall not
          be liable for any indirect, incidental, special, or consequential
          damages arising from or in connection with the use of our services.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          6. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to update or modify these Terms and Conditions at
          any time. Any changes will be effective immediately upon posting. Your
          continued use of our services following any changes constitutes your
          acceptance of the new terms.
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          7. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [Your Jurisdiction]. Any disputes arising from or
          relating to these terms shall be resolved in the courts of [Your
          Jurisdiction].
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at [Contact Information].
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
