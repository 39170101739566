import React from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Card,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import amplifyService from "../../services/amplifyService";
import { SubmitCustomerQueryRequest } from "../../model/services/amplify";

const Contact = () => {
  const theme = useTheme();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [errors, setErrors] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateInputs = () => {
    let valid = true;
    const newErrors = { firstName: "", lastName: "", email: "", message: "" };

    if (!firstName.trim()) {
      newErrors.firstName = "First name is required";
      valid = false;
    }

    if (!lastName.trim()) {
      newErrors.lastName = "Last name is required";
      valid = false;
    }

    if (!email.trim()) {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      newErrors.email = "Email is not valid";
      valid = false;
    }

    if (!message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  async function submitCustomerQuery() {
    if (!validateInputs()) {
      return;
    }

    setButtonDisabled(true);
    setLoading(true);

    const request: SubmitCustomerQueryRequest = {
      emailId: email,
      firstName: firstName,
      lastName: lastName,
      message: message,
    };
    const response = await amplifyService.submitCustomerQuery(request);

    if (response) {
      setSnackbarMessage("Submitted successfully!");
    } else {
      setSnackbarMessage("Unable to send. Please try again later.");
    }

    setSnackbarOpen(true);
    setLoading(false);
    setButtonDisabled(false);
  }

  return (
    <Box
      sx={{
        marginTop: "5rem",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        color={theme.palette.surface?.contrastText}
      >
        Contact Us
      </Typography>
      <Card
        elevation={0}
        sx={{
          backgroundColor: theme.palette.surfaceContainerLow?.main,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "16px",
          margin: "3rem 1.5rem",
          borderRadius: "16px",
        }}
      >
        <Box
          component="form"
          sx={{
            width: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            variant="filled"
            margin="normal"
            required
            color="secondary"
            fullWidth
            id="firstname"
            label="First Name"
            name="firstname"
            autoComplete="First Name"
            sx={{
              marginTop: 0,
              marginBottom: "6px",
              backgroundColor: theme.palette.tertiaryContainer?.main,
            }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            color="secondary"
            fullWidth
            id="lastname"
            label="Last Name"
            name="lastname"
            autoComplete="Last Name"
            sx={{
              marginTop: "6px",
              backgroundColor: theme.palette.tertiaryContainer?.main,
            }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            color="secondary"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            sx={{
              marginTop: "6px",
              backgroundColor: theme.palette.tertiaryContainer?.main,
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            color="secondary"
            fullWidth
            name="message"
            label="Enter your message here"
            id="message"
            multiline
            sx={{
              marginTop: "6px",
              backgroundColor: theme.palette.tertiaryContainer?.main,
            }}
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            error={!!errors.message}
            helperText={errors.message}
          />
          <Box>
            <Button
              disabled={buttonDisabled}
              type="button"
              variant="contained"
              color="primary"
              onClick={submitCustomerQuery}
              sx={{ mt: 1, mb: 0 }}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Card>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );
};

export default Contact;
